import React, { Component } from "react";
import "./App.scss";
import "./assets/css/icofont.min.css";
import "../src/assets/css/animate.css";
import "./assets/css/bootstrap.min.css";
import { BrowserRouter, Route } from "react-router-dom";
import Home from "./Home";
import ContactUs from "./ContactUs";
import Products from "./Products";
import SubProducts from "./SubProducts";
import Footer from "./Footer";

export default class App extends Component {
  render() {
    return (
      <div>
        <BrowserRouter>
          <Route exact path="/" component={Home} />
          <Route path="/contact-us" component={ContactUs} />
          <Route path="/categories/:id" component={Products} />
          <Route path="/products/:id" component={SubProducts} />
          <Route path="/footer" component={Footer} />
        </BrowserRouter>
      </div>
    );
  }
}
