const addToCartWithoutLogin = product => {
  const cartData = localStorage.getItem("products");
  let isAddedToCart = false;
  if (cartData) {
    console.log("data present");
    let parsedData = JSON.parse(cartData);
    const productObj = parsedData.find(
      obj => obj.product_id === product.product_id
    );
    if (productObj) {
      parsedData.forEach(item => {
        if (item.product_id === product.product_id) {
          item.quantity = product.quantity;
        }
      });
    } else {
      parsedData.push(product);
    }
    let stringifiedCartData = JSON.stringify(parsedData);
    localStorage.setItem("products", stringifiedCartData);
    isAddedToCart = true;
  } else {
    console.log("empty");
    let cartArray = [];
    cartArray.push(product);
    let stringifiedCartData = JSON.stringify(cartArray);
    localStorage.setItem("products", stringifiedCartData);
    isAddedToCart = true;
  }
  return isAddedToCart ? "success" : "failed";
};

export default addToCartWithoutLogin;
