import React, { Component } from "react";
import fb from "./assets/images/facebook.png";
import google from "./assets/images/google.png";
export default class Register extends Component {
  render() {
    return (
      <div>
        <div className="Login">
          <div className="row">
            <div className="col-sm-4 loginLeft">
              <h2>Hello,Friend !</h2>
              <h6>
                Enjoy your personal details and start your journey with us.
              </h6>
              <button>Sign Up</button>
            </div>
            <div className="col-sm-8 loginRight">
              <h2>Create Account </h2>
              <h4>Or Sign In With</h4>
              <img src={fb} width="6%" />
              <img src={google} width="6%" />
              <br />
              <p style={{ color: "#ddd" }}>
                ----------------------Or-----------------------
              </p>
              {/* <br /> */}

              <input type="text" placeholder="Username" />
              <br />
              <input type="password" placeholder="Password" />
              <br />
              <button>Sign In</button>
            </div>
          </div>
          {/* <a href="javascript:void(0);" onClick={() => this.closeModal()}>
              Close
            </a> */}
        </div>
      </div>
    );
  }
}
