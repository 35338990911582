import React, { Component } from "react";
import Header from "./Header";
import Footer from "./Footer";
import Axios from "axios";
import Constants from "./Constants";
import { Link } from "react-router-dom";
import menu from "../src/assets/images/menuBlack.png";
import logo from "../src/assets/images/logo.svg";
import close from "../src/assets/images/cancel.png";

export default class Products extends Component {
  state = {
    cat: [],
    name: ""
  };
  componentDidMount = () => {
    window.scrollTo(0, 0);
    window.addEventListener("scroll", () => {
      // console.log(window.scrollY);
      const isTop = window.scrollY < 100;
      if (isTop !== true) {
        this.setState({ scrolled: true });
      } else {
        this.setState({ scrolled: false });
      }
    });
    console.log(this.props);
    let id = this.props.location.state.data.id;
    console.log(id);
    let name = this.props.location.state.data.name;
    console.log(name);

    Axios.get(Constants.getUrls.category + id).then(res => {
      console.log(res.data.sub_category);
      this.setState({
        cat: res.data.sub_category,
        name
      });
    });
  };
  showSideBar() {
    this.setState({
      showMenu: !this.state.showMenu
    });
  }
  close() {
    this.setState({
      showMenu: false
    });
  }
  render() {
    return (
      <div>
        {/* <Header /> */}

        <div className={this.state.scrolled ? "scrollnav" : "header"}>
          <div className="container">
            <div className="row">
              <div className="col-sm-6 col-xs-6">
                <img
                  src={logo}
                  className="imageLogo"
                  width="13%"
                  style={{ marginTop: "10px" }}
                />
              </div>
              <div className="col-sm-6 col-xs-6">
                <div className="show-menu">
                  <ul>
                    <img
                      src={close}
                      width="8%"
                      className="CloseBar imgclose"
                      onClick={() => {
                        this.close();
                      }}
                    />
                    <li>
                      {" "}
                      <a href="/" style={{ color: "black" }}>
                        Home
                      </a>{" "}
                    </li>
                    <li>
                      {" "}
                      <a href="/" style={{ color: "black" }}>
                        About
                      </a>{" "}
                    </li>
                    <li>
                      {" "}
                      <a href="/" style={{ color: "black" }}>
                        Products
                      </a>{" "}
                    </li>
                    <li>
                      {" "}
                      <a
                        href="/contact-us"
                        // style={{ textDecoration: "none", color: "black" }}
                        style={{ color: "black" }}
                      >
                        Contact
                      </a>{" "}
                    </li>
                    {/* <li
                      // key={index}
                      className="download"
                      // onClick={() => {
                      //   this.download(d.image);
                      // }}
                    >
                      Download Brouchure
                    </li> */}
                    <div className="dropdownbtn">
                      {/* {this.state.brouchure.map((d, index) => (
                        <div>
                          <p> {d.title} </p>
                          <li
                            key={index}
                            className="download"
                            onClick={() => {
                              this.download(d.image);
                            }}
                          >
                            Download Brouchure
                          </li>
                        </div>
                      ))} */}
                    </div>
                  </ul>
                </div>
                <img
                  src={menu}
                  width="10%"
                  className="menuProducts"
                  onClick={() => {
                    this.showSideBar();
                  }}
                />
                {this.state.showMenu ? (
                  <div className="menuWrapperContainer">
                    <div className="menuWrapper">
                      {/* <img src={close} width="8%" className="CloseBar" /> */}
                      <ul>
                        <img
                          src={close}
                          width="8%"
                          className="CloseBar"
                          onClick={() => {
                            this.close();
                          }}
                        />
                        <li>
                          {" "}
                          <a href="/">Home</a>{" "}
                        </li>
                        <li>
                          {" "}
                          <a href="#about-us">About</a>{" "}
                        </li>
                        <li>
                          {" "}
                          <a href="#our-products">Products</a>{" "}
                        </li>
                        <li>
                          {" "}
                          <a
                            href="/contact-us"
                            // style={{ textDecoration: "none", color: "black" }}
                          >
                            Contact
                          </a>{" "}
                        </li>
                        {/* <li
                          // key={index}
                          className="download"
                          // onClick={() => {
                          //   this.download(d.image);
                          // }}
                        >
                          Download Brouchure
                        </li> */}
                        {/* <div className="dropdownbtn">
                          {this.state.brouchure.map((d, index) => (
                            <div>
                              <p> {d.title} </p>
                              <li
                                key={index}
                                className="download"
                                onClick={() => {
                                  this.download(d.image);
                                }}
                              >
                                Download Brouchure
                              </li>
                            </div>
                          ))}
                        </div> */}
                      </ul>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>

        <div className="Products">
          <div className="container">
            <div className="card-grid">
              <div className="titlWrapper">
                <h1>
                  {this.state.name}
                  <hr />
                </h1>
                {/* <h4>Decorative, 3D Wall Panels</h4> */}
              </div>
              <div className="grid-container">
                <div className="row">
                  {this.state.cat.map(v => (
                    <div className="col-sm-4">
                      <Link
                        to={{
                          pathname: "/products/" + v.id,
                          state: { data: v }
                        }}
                        style={{ textDecoration: "none" }}
                      >
                        <div className="card grid-item">
                          <img
                            src={Constants.serverDomain + v.image}
                            width="100%"
                            height=" 300px"
                          />
                          <div class="card-body">
                            <h4 class="card-title"> {v.name} </h4>
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}
