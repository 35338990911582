import React, { Component } from "react";
import Header from "./Header";
import Footer from "./Footer";
import ScrollAnimation from "react-animate-on-scroll";
import { Link } from "react-router-dom";
import Axios from "axios";
// import { Slide } from "react-slideshow-image";
import Slider from "react-slick";
// import { Slider3 } from "../src/services/script";
import Constants from "./Constants";
import menu from "../src/assets/images/menu.png";
import close from "../src/assets/images/cancel.png";
import main from "../src/assets/images/background.jpg";
import logo from "../src/assets/images/logo.svg";
import whyUs from "../src/assets/images/whyUs.jpg";
import GoogleMapReact from "google-map-react";
import Modal from "react-awesome-modal";
import addtowishlist from "./AddWishlist";

import renderHTML from "react-render-html";
import {
  CarouselProvider,
  // Slider,
  // Slide,
  ButtonBack,
  ButtonNext
} from "pure-react-carousel";
const windoWidth = window.innerWidth;
export default class Home extends Component {
  state = {
    homeproducts: [],
    brouchure: [],
    showMenu: false,
    full_name: "",
    showwishProducts: false,
    email: "",
    messages: "",
    successMsg: false,
    sending: false,
    error: false,
    showSelected: false,
    Products: [],
    isAddedToCart: false
  };
  componentDidMount = () => {
    console.log(windoWidth);
    window.addEventListener("scroll", () => {
      // console.log(window.scrollY);
      const isTop = window.scrollY < 100;
      if (isTop !== true) {
        this.setState({ scrolled: true });
      } else {
        this.setState({ scrolled: false });
      }
    });
    Axios.get(Constants.getUrls.brochre).then(res => {
      console.log(res);
      this.setState({
        brouchure: res.data
      });
    });
    Axios.get(Constants.getUrls.ProductsInHome).then(resp => {
      console.log(resp);
      this.setState({
        homeproducts: resp.data
      });
    });
    Axios.get(Constants.getUrls.Products).then(res => {
      console.log(res.data.data);
      this.setState({
        Products: res.data.data
      });
    });
  };
  download(filepath) {
    Axios.get("http://localhost:8000/api/download_file?file=" + filepath).then(
      res => {
        console.log(res);
        // window.open("_blank");
      }
    );
  }
  showSideBar() {
    this.setState({
      showMenu: !this.state.showMenu
    });
  }
  close() {
    this.setState({
      showMenu: false
    });
  }
  openModal() {
    this.setState({
      visible: true
    });
  }
  addtowishlist(id, name, image, desc) {
    const payload = {
      product_id: id,
      product_name: name,
      image: image,
      desc: desc
    };
    console.log(payload);

    // let data = [];
    // data.push(payload);
    // console.log(payload);
    // localStorage.setItem("products", JSON.stringify(data));
    if (addtowishlist(payload) === "success")
      this.setState({
        showwishProducts: true,
        showSelected: true
      });
    setTimeout(() => {
      this.setState({
        showwishProducts: false
      });
    }, 2000);
  }
  closeModal() {
    this.setState({
      visible: false
    });
  }
  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };
  onSend = filepath => {
    const payload = {
      full_name: this.state.full_name,

      email: this.state.email,
      messages: this.state.messages
    };
    console.log(payload);
    Axios.post(Constants.postUrls.contactUs, payload).then(res => {
      console.log(res);
      this.setState({
        sending: true
      });
      if (res.data === "successfully send your request") {
        this.setState({
          successMsg: true,
          sendmsg: res.data,
          sending: false,
          redirect: true
        });
        Axios.get(
          "https://core.hikjichi.com/api/download_file?file=" + filepath
        ).then(res => {
          console.log(res);
          window.open("_blank");
        });
      }
    });
  };
  render() {
    const Slider1 = {
      infinite: true,
      speed: 300,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };

    const properties = {
      duration: 3000,
      transitionDuration: 200,
      infinite: true,
      arrows: true,
      pauseOnHover: false,
      indicators: true,
      onChange: (oldIndex, newIndex) => {
        // console.log(`slide transition from ${oldIndex} to ${newIndex}`);
      }
    };
    return (
      <div>
        {/* <Header /> */}
        <div className={this.state.scrolled ? "scrollnav" : "header"}>
          <div className="container">
            <div className="row">
              <div className="col-sm-6 col-xs-6">
                <img
                  src={logo}
                  className="imageLogo"
                  width="13%"
                  style={{ marginTop: "10px" }}
                />
              </div>
              <div className="col-sm-6 col-xs-6">
                <div className="show-menu">
                  <ul>
                    <img
                      src={close}
                      width="8%"
                      className="CloseBar imgclose"
                      onClick={() => {
                        this.close();
                      }}
                    />
                    <li>
                      {" "}
                      <a href="#home">Home</a>{" "}
                    </li>
                    <li>
                      {" "}
                      <a href="#about-us">About</a>{" "}
                    </li>
                    <li>
                      {" "}
                      <a href="#our-products">Products</a>{" "}
                    </li>
                    <li>
                      {" "}
                      <a
                        href="/contact-us"
                      // style={{ textDecoration: "none", color: "black" }}
                      >
                        Contact
                      </a>{" "}
                    </li>

                    {/* <div className="dropdownbtn"></div> */}
                  </ul>
                </div>
                <img
                  src={menu}
                  width="10%"
                  className="menu"
                  onClick={() => {
                    this.showSideBar();
                  }}
                />
                {this.state.showMenu ? (
                  <div className="menuWrapperContainer">
                    <div className="menuWrapper">
                      {/* <img src={close} width="8%" className="CloseBar" /> */}
                      <ul>
                        <img
                          src={close}
                          width="8%"
                          className="CloseBar"
                          onClick={() => {
                            this.close();
                          }}
                        />
                        <li>
                          {" "}
                          <a href="#home">Home</a>{" "}
                        </li>
                        <li>
                          {" "}
                          <a href="#about-us">About</a>{" "}
                        </li>
                        <li>
                          {" "}
                          <a href="#our-products">Products</a>{" "}
                        </li>
                        <li>
                          {" "}
                          <a
                            href="/contact-us"
                          // style={{ textDecoration: "none", color: "black" }}
                          >
                            Contact
                          </a>{" "}
                        </li>
                        {/* <li
                          // key={index}
                          className="download"
                          // onClick={() => {
                          //   this.download(d.image);
                          // }}
                        >
                          Download Brouchure
                        </li> */}
                        <div className="dropdownbtn"></div>
                      </ul>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <Modal
          visible={this.state.visible}
          width={windoWidth <= 700 ? "80%" : "400"}
          // width="400"
          // height="300"
          effect="fadeInUp"
          onClickAway={() => this.closeModal()}
          class="mdl"
        >
          <div className="modalDownLoad">
            <h3>Download Brouchure</h3>

            <input
              type="text"
              placeholder="Name"
              name="full_name"
              onChange={this.handleChange}
            />
            <br />
            <input
              type="text"
              placeholder="Email"
              name="email"
              onChange={this.handleChange}
            />
            <br />
            <input
              type="text"
              placeholder="Message"
              name="messages"
              onChange={this.handleChange}
            />
            <br />

            {this.state.brouchure.length > 0 ? (
              <div>
                {this.state.brouchure.map((d, index) => (
                  <div style={{ textAlign: "center" }}>
                    <a href={"https://core.hikajichi.com/api/download_file?file=" + d.image} target="_blank">
                      <li
                        style={{
                          listStyle: "none",
                          width: "70%",
                          marginLeft: "53px"
                        }}
                        key={index}
                        className="download"
                        onClick={() => this.onSend(d.image)}
                      >
                        Submit
                    </li>
                    </a>

                  </div>
                ))}
              </div>
            ) : (
                <button>Submit</button>
              )}
          </div>
        </Modal>

        <ScrollAnimation
          animateIn="fadeInUp"
          animationInDuration={1800}
          isVisible={true}
          animateOnce={false}
        >
          <div className="home" id="home">
            <div className="row">
              <div className="col-sm-5 fadeInLeftToRight">
                <h1>
                  A World of Live Edge
                  <br /> Furniture
                </h1>
                <button>
                  <a
                    href="/contact-us"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    Contact Us
                  </a>{" "}
                </button>
              </div>
              <div className="col-sm-7 homeright">
                {/* <img src={main} width="100%" /> */}
              </div>
            </div>
          </div>
          <div className="homeInResp" id="home">
            <div className="row">
              <div className="col-sm-7 homeright"></div>
              <div className="col-sm-5 fadeInLeftToRight">
                <h1>
                  A World of Live Edge
                  <br /> Furniture
                </h1>
                <button>
                  <a
                    href="/contact-us"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    Contact Us
                  </a>{" "}
                </button>
              </div>
            </div>
          </div>
        </ScrollAnimation>

        <ScrollAnimation
          animateIn="fadeInUp"
          animationInDuration={1800}
          isVisible={true}
          animateOnce={false}
        >
          <div className="about container fadeInTopToBottom" id="about-us">
            <div className="row">
              <div className="col-sm-4">
                <h1>About us</h1>
                <hr />
                <h5>
                  The Hikajichi Enterprises, markets a range of architectural
                  wood products, specializing in custom-cutproduct specially in
                  Live Edge Furniture and other Wooden Furniture for high-end
                  residential,commercial, institutional and resort applications
                  since last two decades.
                </h5>
                <h5>
                  The Company always maintain the stock of more than 1000~1200
                  table tops and 5000~6000 Cubic Feet of material in raw form to
                  match the customer requirements. We have started marketing of
                  our products under the brand name “Woodees”.With presence in
                  Kolkatta, Pune, Nagpur, Hyderabad in India, we insure quality
                  service to our valued customers. Our live edge furniture has
                  uniqueness as it is carved out from wood which aged more than
                  200 years.
                </h5>
              </div>
              <div className="col-sm-4">
                <img src={logo} width="100%" />
              </div>

              <div className="col-sm-4">
                <h2>Live edge furniture</h2>
                <h5>
                  Live edge furniture exudes rich natural colors, textures, and
                  beautiful craftsmanship. Our skilled designers and carpenters
                  incorporate the natural edge of the wood into the design of
                  the piece, whether a table, desk, bench, coffee table or
                  another custom item.
                </h5>
                <h5>
                  Live edge allows you to create a warm vibe that only natural
                  wood in its natural free contoured state can bring. Every
                  piece is unique, chemically treated and Kiln Dried (to
                  maintain moisture further enhancing is life) Beyond the
                  artistic beauty that live edge furniture provides, one of the
                  most attractive things about it is its sustainability. In much
                  of the live edge furniture being created, the wood used is
                  often sourced locally from fallen trees through Government
                  Auctions.
                </h5>
              </div>
            </div>
          </div>
        </ScrollAnimation>

        {/* <div className="products">
          <h1>
            What we do?
            <hr />
          </h1>
          <h5>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatem
            voluptatum in expedita doloribus molestiae beatae nemo consequuntur.
          </h5>
          <div className="card-grid">
            <div className="card">
              <div className="row">
                <div className="col-sm-6">
                  <h3>Handcrafted</h3>
                  <h5>
                    Cras convallis ut turpis vitae facilisis. Morbi eu augue vel
                    quam efficitur rhoncus vitae eget lectus. Cras augue ligula,
                    aliquam ut enim ut, feugiat imperdiet sem. Integer sed mi
                    quis nisl eleifend interdum.
                  </h5>
                </div>
                <div className="col-sm-6">
                  <div className="card-image"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-grid">
            <div className="card">
              <div className="row">
                <div className="col-sm-6">
                  <h3>Handcrafted</h3>
                  <h5>
                    Cras convallis ut turpis vitae facilisis. Morbi eu augue vel
                    quam efficitur rhoncus vitae eget lectus. Cras augue ligula,
                    aliquam ut enim ut, feugiat imperdiet sem. Integer sed mi
                    quis nisl eleifend interdum.
                  </h5>
                </div>
                <div className="col-sm-6">
                 
                  <div className="card-image"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-grid">
            <div className="card">
              <div className="row">
                <div className="col-sm-6">
                  <h3>Handcrafted</h3>
                  <h5>
                    Cras convallis ut turpis vitae facilisis. Morbi eu augue vel
                    quam efficitur rhoncus vitae eget lectus. Cras augue ligula,
                    aliquam ut enim ut, feugiat imperdiet sem. Integer sed mi
                    quis nisl eleifend interdum.
                  </h5>
                </div>
                <div className="col-sm-6">
                 
                  <div className="card-image"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-grid">
            <div className="card">
              <div className="row">
                <div className="col-sm-6">
                  <h3>Handcrafted</h3>
                  <h5>
                    Cras convallis ut turpis vitae facilisis. Morbi eu augue vel
                    quam efficitur rhoncus vitae eget lectus. Cras augue ligula,
                    aliquam ut enim ut, feugiat imperdiet sem. Integer sed mi
                    quis nisl eleifend interdum.
                  </h5>
                </div>
                <div className="col-sm-6">
                  <div className="card-image"></div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <ScrollAnimation
          animateIn="fadeInUp"
          animationInDuration={1800}
          isVisible={true}
          animateOnce={false}
        >
          {this.state.showwishProducts ? (
            <div className="notification">
              <p>Item Added To Wishlist</p>
            </div>
          ) : null}
          <div
            className="our-products"
            id="our-products"
            style={{ textAlign: "center" }}
          >
            <h1>
              Our Products
              <hr />
            </h1>
            <div className="container">
              <div className="card-grid">
                {this.state.homeproducts.map((singleSlide, index) => (
                  <Link
                    to={{
                      pathname: "/categories/" + singleSlide.id,
                      state: { data: singleSlide }
                    }}
                    style={{
                      textDecoration: "none",
                      color: "black"
                    }}
                  >
                    <div class="card">
                      <img
                        src={Constants.serverDomain + singleSlide.image}
                        width="100%"
                        className="image card-img-top"
                      />

                      <div class="card-body">
                        <h4 class="card-title">{singleSlide.name} </h4>
                        <p class="card-title">
                          {renderHTML(singleSlide.description)}
                        </p>

                        <div class="btn">
                          <Link
                            to={{
                              pathname: "/categories/" + singleSlide.id,
                              state: { data: singleSlide }
                            }}
                            style={{
                              textDecoration: "none",
                              color: "black"
                            }}
                          >
                            View More..
                          </Link>
                        </div>
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
              <h1 style={{ marginTop: "100px", marginBottom: "70px" }}>
                Our Latest Products <hr />
              </h1>
              <div className="row" style={{ textAlign: "center" }}>
                {/* <div className="col-sm-12"> */}
                <Slider {...Slider1}>
                  {this.state.Products.map((single, index) => (
                    <div className="col-sm-3">
                      <div class="card">
                        <img
                          src={Constants.serverDomain + single.image}
                          width="100%"
                          className="image card-img-top"
                          height=" 300px"
                        />

                        <div class="card-body">
                          <h4 class="card-title">{single.name} </h4>

                          <div class="btn" style={{ marginRight: "5px" }}>
                            <i
                              class="icofont-heart"
                              style={{
                                fontSize: "20px"
                              }}
                              onClick={() => {
                                this.addtowishlist(
                                  single.id,
                                  single.name,
                                  single.image,
                                  single.description
                                );
                              }}
                              key={index}
                            // style={{
                            //   color: this.state.showSelected
                            //     ? "red"
                            //     : " rgb(133, 116, 116)"
                            // }}
                            ></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
              {/* </div> */}
            </div>

            <div className="downWrapper" style={{ textAlign: "center" }}>
              <li
                style={{
                  listStyle: "none",
                  width: "103%",
                  textAlign: "center",
                  cursor: "pointer"
                }}
                // key={index}
                className="download"
                // onClick={() => {
                //   this.download(d.image);
                // }}
                onClick={() => this.openModal()}
              >
                Download Brouchure
              </li>
            </div>
          </div>
        </ScrollAnimation>
        <ScrollAnimation
          animateIn="fadeInUp"
          animationInDuration={1800}
          isVisible={true}
          animateOnce={false}
        >
          <div className="connect-us">
            <h1>Connect with us:</h1>
            <div className="container">
              <div className="row">
                <div className="col-sm-4 leftpart">
                  <h2>NAGPUR</h2>
                  <h4>104,Avg layout, chhapru NAGAR</h4>
                  <h4>square, Lakadganj, Nagpur,</h4>
                  <h4 style={{ marginBottom: "30px" }}>Maharashtra 440008</h4>
                  <h4 style={{ padding: "5px" }}>
                    {" "}
                    <b>Phone :</b> +91-942-356-5284
                  </h4>
                  <h4 style={{ padding: "5px", marginBottom: "30px" }}>
                    <b> Email:</b> thehikajichi@gmail.com
                  </h4>
                  {/* <GoogleMapReact
                  bootstrapURLKeys={{ key: bdsb }}
                  defaultCenter={this.props.center}
                  defaultZoom={this.props.zoom}
                >
                  <AnyReactComponent
                    lat={59.955413}
                    lng={30.337844}
                    text="My Marker"
                  />
                </GoogleMapReact> */}
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4330.3135112159825!2d79.12293710341339!3d21.149210103525757!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd4c7391db66c79%3A0x1f6d324478536dff!2sTHE+BHAGWAT+HOMES!5e0!3m2!1sen!2sin!4v1540881737302"
                    frameborder="0"
                    width="100%"
                    height="300px"
                    style={{ border: "0" }}
                  // allowfullscreen
                  ></iframe>
                </div>
                <div className="col-sm-4 leftpart">
                  <h2>PUNE</h2>
                  <h4>25, Mahalaxmi Park Behind Balaji Petrol</h4>
                  <h4>
                    Pump,Hadapsar-Saswad Raod,Uralidevaci,Pune(Maharashtra){" "}
                  </h4>
                  <h4 style={{ marginBottom: "30px" }}>412308</h4>
                  <h4 style={{ padding: "5px" }}>
                    {" "}
                    <b>Phone :</b> +91-942-356-5284
                  </h4>
                  <h4 style={{ padding: "5px", marginBottom: "30px" }}>
                    <b> Email:</b> thehikajichi@gmail.com
                  </h4>
                  {/* <GoogleMapReact
                  bootstrapURLKeys={{ key: fhj }}
                  defaultCenter={this.props.center}
                  defaultZoom={this.props.zoom}
                >
                  <AnyReactComponent
                    lat={59.955413}
                    lng={30.337844}
                    text="My Marker"
                  />
                </GoogleMapReact> */}
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3783.427322143243!2d73.86475341484251!3d18.509581887415358!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2c040470caedf%3A0x6373c08e81b29600!2s27%2C+New+Timber+Market+Rd%2C+New+Timber+Market%2C+Ganj+Peth%2C+Pune%2C+Maharashtra+411002!5e0!3m2!1sen!2sin!4v1540657112197"
                    frameborder="0"
                    width="100%"
                    height="300px"
                    style={{ border: "0" }}
                    allowfullscreen
                  ></iframe>
                </div>
                <div className="col-sm-4 rightpart">
                  <h2>Jodhpur</h2>
                  <h4>
                    60, Govindham Industries Area Salawas to Boranada Road,{" "}
                  </h4>
                  <h4 style={{ marginBottom: "40px" }}>
                    Salawas, Jodhpur, Rajshtan - 342013{" "}
                  </h4>
                  {/* <h4 style={{ marginBottom: "30px" }}>342013</h4> */}
                  <h4 style={{ padding: "5px" }}>
                    {" "}
                    <b>Phone :</b> +91-942-356-5284
                  </h4>
                  <h4 style={{ padding: "5px", marginBottom: "30px" }}>
                    <b> Email:</b> thehikajichi@gmail.com
                  </h4>
                  {/* <GoogleMapReact
                  bootstrapURLKeys={{ key: fhj }}
                  defaultCenter={this.props.center}
                  defaultZoom={this.props.zoom}
                >
                  <AnyReactComponent
                    lat={59.955413}
                    lng={30.337844}
                    text="My Marker"
                  />
                </GoogleMapReact> */}
                  {/* <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3783.427322143243!2d73.86475341484251!3d18.509581887415358!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2c040470caedf%3A0x6373c08e81b29600!2s27%2C+New+Timber+Market+Rd%2C+New+Timber+Market%2C+Ganj+Peth%2C+Pune%2C+Maharashtra+411002!5e0!3m2!1sen!2sin!4v1540657112197"
                    frameborder="0"
                    width="100%"
                    height="300px"
                    style={{ border: "0" }}
                    allowfullscreen
                  ></iframe> */}
                  {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d457647.75197233504!2d73.00658780699743!3d26.34679308230655!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0!2zMjbCsDA4JzQ2LjkiTiA3MsKwNTgnMzMuOSJF!5e0!3m2!1sen!2sin!4v1605169946017!5m2!1sen!2sin" width="600" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe> */}
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d457647.75197233504!2d73.00658780699743!3d26.34679308230655!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0!2zMjbCsDA4JzQ2LjkiTiA3MsKwNTgnMzMuOSJF!5e0!3m2!1sen!2sin!4v1605169946017!5m2!1sen!2sin"
                    width="100%"
                    height="300px"
                    frameborder="0"
                    style={{ border: 0 }}
                    allowfullscreen=""
                    aria-hidden="false"
                    tabindex="0"
                  ></iframe>
                  {/* <iframe
                    src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d3579.6673108069813!2d72.92900211430606!3d26.207498583436404!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1s60%2Cgovindam%20industries%20area%20salawas%20to%20boranada%20road%20salawas%20jodhpur%20(rajsthan)!5e0!3m2!1sen!2sin!4v1603349396482!5m2!1sen!2sin"
                    width="100%"
                    height="300px"
                    frameborder="0"
                    // style="border:0;"
                    allowfullscreen=""
                    aria-hidden="false"
                    tabindex="0"
                  ></iframe> */}
                </div>
              </div>
            </div>
          </div>
        </ScrollAnimation>

        <Footer />
      </div >
    );
  }
}
