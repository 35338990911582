import React, { Component } from "react";
import search from "./assets/images/search.png";
import Modal from "react-awesome-modal";
import Login from "./Login";
import Register from "./Register";
import menu from "../src/assets/images/hamburger.png";
// import menu from "../src/assets/images/hamburger.png";
import logo from "../src/assets/images/logo.svg";
import close from "../src/assets/images/cancel.png";

import Axios from "axios";
import Constants from "./Constants";
import { Link } from "react-router-dom";
export default class Header extends Component {
  state = {
    visible: false,
    showReg: false,
    scrolled: false,
    brouchure: []
  };
  download(filepath) {
    Axios.get("http://localhost:8000/api/download_file?file=" + filepath).then(
      res => {
        console.log(res);
        // window.open("_blank");
      }
    );
  }
  componentDidMount() {
    window.addEventListener("scroll", () => {
      // console.log(window.scrollY);
      const isTop = window.scrollY < 100;
      if (isTop !== true) {
        this.setState({ scrolled: true });
      } else {
        this.setState({ scrolled: false });
      }
    });
    Axios.get(Constants.getUrls.brochre).then(res => {
      console.log(res);
      this.setState({
        brouchure: res.data
      });
    });
  }
  render() {
    return (
      // <div className={this.state.scrolled ? "scrollnav" : "header"}>
      //   <div className="container">
      //     <div className="row">
      //       <div className="col-sm-6">
      //         <img
      //           src={logo}
      //           width="13%"
      //           style={{ marginTop: "10px" }}
      //           className="imageLogo"
      //         />
      //       </div>
      //       <div className="col-sm-6">
      //         <img src={menu} width="10%" className="menu" />
      //         <ul>
      //           <li>Home </li>
      //           <li>About </li>
      //           <li> Products</li>
      //           <li>
      //             {" "}
      //             <a
      //               href="/contact-us"
      //               // style={{ textDecoration: "none", color: "black" }}
      //             >
      //               Contact
      //             </a>{" "}
      //           </li>

      //           <div className="dropdownbtn">
      //             {this.state.brouchure.map((d, index) => (
      //               <div>
      //                 <p> {d.title} </p>
      //                 <li
      //                   key={index}
      //                   className="download"
      //                   onClick={() => {
      //                     this.download(d.image);
      //                   }}
      //                 >
      //                   Download Brouchure
      //                 </li>
      //               </div>
      //             ))}
      //           </div>
      //         </ul>
      //       </div>
      //     </div>
      //   </div>
      // </div>

      <div className={this.state.scrolled ? "scrollnav" : "header"}>
        <div className="container">
          <div className="row">
            <div className="col-sm-6 col-xs-6">
              <img
                src={logo}
                className="imageLogo"
                width="13%"
                style={{ marginTop: "10px" }}
              />
            </div>
            <div className="col-sm-6 col-xs-6">
              <div className="show-menu">
                <ul>
                  <img
                    src={close}
                    width="8%"
                    className="CloseBar"
                    onClick={() => {
                      this.close();
                    }}
                  />
                  <li>
                    {" "}
                    <a href="#home">Home</a>{" "}
                  </li>
                  <li>
                    {" "}
                    <a href="#about-us">About</a>{" "}
                  </li>
                  <li>
                    {" "}
                    <a href="#our-products">Products</a>{" "}
                  </li>
                  <li>
                    {" "}
                    <a
                      href="/contact-us"
                      // style={{ textDecoration: "none", color: "black" }}
                    >
                      Contact
                    </a>{" "}
                  </li>
                  <li
                    // key={index}
                    className="download"
                    // onClick={() => {
                    //   this.download(d.image);
                    // }}
                  >
                    Download Brouchure
                  </li>
                  <div className="dropdownbtn">
                    {this.state.brouchure.map((d, index) => (
                      <div>
                        <p> {d.title} </p>
                        <li
                          key={index}
                          className="download"
                          onClick={() => {
                            this.download(d.image);
                          }}
                        >
                          Download Brouchure
                        </li>
                      </div>
                    ))}
                  </div>
                </ul>
              </div>
              <img
                src={menu}
                width="10%"
                className="menu"
                onClick={() => {
                  this.showSideBar();
                }}
              />
              {this.state.showMenu ? (
                <div className="menuWrapperContainer">
                  <div className="menuWrapper">
                    {/* <img src={close} width="8%" className="CloseBar" /> */}
                    <ul>
                      <img
                        src={close}
                        width="8%"
                        className="CloseBar"
                        onClick={() => {
                          this.close();
                        }}
                      />
                      <li>
                        {" "}
                        <a href="#home">Home</a>{" "}
                      </li>
                      <li>
                        {" "}
                        <a href="#about-us">About</a>{" "}
                      </li>
                      <li>
                        {" "}
                        <a href="#our-products">Products</a>{" "}
                      </li>
                      <li>
                        {" "}
                        <a
                          href="/contact-us"
                          // style={{ textDecoration: "none", color: "black" }}
                        >
                          Contact
                        </a>{" "}
                      </li>
                      <li
                        // key={index}
                        className="download"
                        // onClick={() => {
                        //   this.download(d.image);
                        // }}
                      >
                        Download Brouchure
                      </li>
                      <div className="dropdownbtn">
                        {this.state.brouchure.map((d, index) => (
                          <div>
                            <p> {d.title} </p>
                            <li
                              key={index}
                              className="download"
                              onClick={() => {
                                this.download(d.image);
                              }}
                            >
                              Download Brouchure
                            </li>
                          </div>
                        ))}
                      </div>
                    </ul>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
