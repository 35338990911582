import React, { Component } from "react";
import Footer from "./Footer";
import Header from "./Header";
import Axios from "axios";
import Constants from "./Constants";
import send from "../src/assets/images/send.json";
import Lottie from "lottie-react-web";
import menu from "../src/assets/images/menuBlack.png";
// import menu from "../src/assets/images/menu.png";

import logo from "../src/assets/images/logo.svg";
import { Link } from "react-router-dom";
import close from "../src/assets/images/cancel.png";

export default class ContactUs extends Component {
  state = {
    full_name: "",

    email: "",
    messages: "",
    successMsg: false,
    sending: false,
    error: false,
    Wishlist: []
  };
  componentDidMount = () => {
    window.scrollTo(0, 0);

    let data = localStorage.getItem("products");
    let newvar = JSON.parse(data);
    console.log(newvar);
    this.setState({
      Wishlist: newvar
    });
    window.addEventListener("scroll", () => {
      // console.log(window.scrollY);
      const isTop = window.scrollY < 100;
      if (isTop !== true) {
        this.setState({ scrolled: true });
      } else {
        this.setState({ scrolled: false });
      }
    });
  };
  onSend = e => {
    this.setState({
      sending: true
    });
    const payload = {
      full_name: this.state.full_name,
      email: this.state.email,
      messages: this.state.messages,
      products: this.state.Wishlist
    };
    console.log(payload);
    Axios.post(Constants.postUrls.contactUs, payload).then(res => {
      console.log(res);
      this.setState({
        sending: true
      });
      if (res.data === "successfully send your request") {
        this.setState({
          successMsg: true,
          sendmsg: res.data,
          sending: false,
          redirect: true
        });
        setTimeout(() => {
          // this.setState({
          //   successMsg: true
          // });
          // window.location.href = "/";
        }, 2000);
      } else {
        this.setState({
          error: true,
          loading: false
        });
      }
    });
  };

  showSideBar() {
    this.setState({
      showMenu: !this.state.showMenu
    });
  }
  close() {
    this.setState({
      showMenu: false
    });
  }
  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };
  render() {
    return (
      <div>
        {/* <Header /> */}
        <div className={this.state.scrolled ? "scrollnav" : "header"}>
          <div className="container">
            <div className="row">
              <div className="col-sm-6 col-xs-6">
                <img
                  src={logo}
                  className="imageLogo"
                  width="13%"
                  style={{ marginTop: "10px" }}
                />
              </div>
              <div className="col-sm-6 col-xs-6">
                <div className="show-menu">
                  <ul>
                    <img
                      src={close}
                      width="8%"
                      className="CloseBar imgclose"
                      onClick={() => {
                        this.close();
                      }}
                    />
                    <li>
                      {" "}
                      <a href="/" style={{ color: "black" }}>
                        Home
                      </a>{" "}
                    </li>
                    <li>
                      {" "}
                      <a href="/" style={{ color: "black" }}>
                        About
                      </a>{" "}
                    </li>
                    <li>
                      {" "}
                      <a href="/" style={{ color: "black" }}>
                        Products
                      </a>{" "}
                    </li>
                    <li>
                      {" "}
                      <a
                        href="/contact-us"
                        // style={{ textDecoration: "none", color: "black" }}
                        style={{ color: "black" }}
                      >
                        Contact
                      </a>{" "}
                    </li>
                    {/* <li
                      // key={index}
                      className="download"
                      // onClick={() => {
                      //   this.download(d.image);
                      // }}
                    >
                      Download Brouchure
                    </li> */}
                    <div className="dropdownbtn">
                      {/* {this.state.brouchure.map((d, index) => (
                        <div>
                          <p> {d.title} </p>
                          <li
                            key={index}
                            className="download"
                            onClick={() => {
                              this.download(d.image);
                            }}
                          >
                            Download Brouchure
                          </li>
                        </div>
                      ))} */}
                    </div>
                  </ul>
                </div>
                <img
                  src={menu}
                  width="10%"
                  className="menuContact"
                  onClick={() => {
                    this.showSideBar();
                  }}
                />
                {this.state.showMenu ? (
                  <div className="menuWrapperContainer">
                    <div className="menuWrapper">
                      {/* <img src={close} width="8%" className="CloseBar" /> */}
                      <ul>
                        <img
                          src={close}
                          width="8%"
                          className="CloseBar"
                          onClick={() => {
                            this.close();
                          }}
                        />
                        <li>
                          {" "}
                          <a href="/">Home</a>{" "}
                        </li>
                        <li>
                          {" "}
                          <a href="/">About</a>{" "}
                        </li>
                        <li>
                          {" "}
                          <a href="/">Products</a>{" "}
                        </li>
                        <li>
                          {" "}
                          <a
                            href="/contact-us"
                            // style={{ textDecoration: "none", color: "black" }}
                          >
                            Contact
                          </a>{" "}
                        </li>
                        {/* <li
                          // key={index}
                          className="download"
                          // onClick={() => {
                          //   this.download(d.image);
                          // }}
                        >
                          Download Brouchure
                        </li> */}
                        {/* <div className="dropdownbtn">
                          {this.state.brouchure.map((d, index) => (
                            <div>
                              <p> {d.title} </p>
                              <li
                                key={index}
                                className="download"
                                onClick={() => {
                                  this.download(d.image);
                                }}
                              >
                                Download Brouchure
                              </li>
                            </div>
                          ))}
                        </div> */}
                      </ul>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>

        <div className="contact">
          <div className="container">
            {this.state.successMsg ? (
              <div
                style={{
                  position: "absolute",
                  top: "35%",
                  left: "75%",
                  display: "flex",
                  background: "#fff",
                  padding: "5px 20px",
                  borderRadius: "8px",
                  color: "black",
                  boxShadow: "4px 4px 16px #cdcdcd"
                }}
              >
                <Lottie
                  options={{
                    animationData: send
                  }}
                  style={{
                    width: "30px",
                    height: "30px",
                    marginTop: "5px"
                  }}
                />
                <p>{this.state.sendmsg} </p>
              </div>
            ) : null}
            <div className="row" style={{ marginTop: "150px" }}>
              <div className="col-sm-7">
                <h1>Your Shortlisted Products</h1>
                {/* <h5>You have not shortlisted any product</h5> */}
                <div className="row">
                  <div className="our-products">
                    {this.state.Wishlist
                      ? this.state.Wishlist.map(hh => (
                          <div className="col-sm-6">
                            <div className="card">
                              <img
                                src={Constants.serverDomain + hh.image}
                                width="100%"
                                className="image card-img-top"
                              />
                              <div class="card-body">
                                <h4 class="card-title">{hh.product_name} </h4>
                                {/* <p class="card-text"> {hh.desc} </p> */}
                                {/* <div class="btn">
                          <Link
                            to={{
                              pathname: "/categories/" + hh.id,
                              state: { data: hh }
                            }}
                          >
                            View More..
                          </Link>
                        </div> */}
                              </div>
                            </div>
                          </div>
                        ))
                      : null}
                  </div>
                </div>
              </div>
              <div className="col-sm-5">
                <div className="right">
                  <h1>ENQUIRY</h1>
                  <h5>
                    Please fill out the quick form and we will be in touch with
                    you about your shortlisted products.
                  </h5>
                  <input
                    type="text"
                    placeholder="Name"
                    name="full_name"
                    onChange={this.handleChange}
                  />
                  <br />
                  <input
                    type="text"
                    placeholder="Email"
                    name="email"
                    onChange={this.handleChange}
                  />
                  <br />
                  <input
                    type="text"
                    placeholder="Message"
                    name="messages"
                    onChange={this.handleChange}
                  />
                  <br />
                  <button onClick={this.onSend}>Submit</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
