import React, { Component } from "react";

export default class Footer extends Component {
  render() {
    return (
      <div className="footer">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <h2>HIKAJICHI</h2>
              <p>
                The Hikajichi Enterprises, markets a range of architectural wood
                products, specializing in custom-cutproduct specially in Live
                Edge Furniture and other Wooden Furniture for high-end
                residential,commercial, institutional and resort applications
                since last two decades.
              </p>
            </div>
            <ul className="col-md-4">
              <li>
                <strong>
                  <h2>Site Links</h2>
                </strong>
              </li>
              <li>
                <a href="#home">Home</a>
              </li>
              <li>
                <a href="#about-us">About</a>
              </li>
              <li>
                <a href="/contact-us">Contact</a>
              </li>
            </ul>

            <ul className="col-md-4">
              <li>
                <strong>
                  <h2>Social Networks</h2>
                </strong>
              </li>
              <li>
                <a href="#">Facebook</a>
              </li>
              <li>
                <a href="#">Twitter</a>
              </li>
              <li>
                <a href="#">Email</a>
              </li>
              <li>
                <a href="#">Linkedin</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="row copyright">
          <div class="col-md-12">
            <p>Copyright © 2018 HIKAJICHI. All Rights Reserved.</p>
          </div>
          <div class="col-md-12" id="design">
            <p>Designed By The Space Element </p>
          </div>
        </div>
      </div>
    );
  }
}
